import { IconButton } from "@mui/material"
import { googleLogout } from "@react-oauth/google"
import { Link, useNavigate } from "react-router-dom"
import { removeAccessToken } from "../../utils/auth"
import css from "./Header.module.css"

const Header = ({ onMenuOpen })=> {

  const navigate = useNavigate()

  const onLogout = () => {
    removeAccessToken()
    googleLogout()
    navigate('/login')
  }
  
  return (
    <header className={css.header}>
      <div className={css.logos}>
        <Link className={css.logoLink} to='/mundoestudiante'>
          <img 
            className={css.logo}
            data-logo='mundoestudiante'
            src="/mundoestudiante--white.png" 
            alt="mundoestudiante" 
          />
        </Link>
        <span className={css.separator}>x</span>
        <Link className={css.logoLink} to='/classfy'>
          <img 
            className={css.logo} 
            data-logo='classfy'
            src="/classfy.png" 
            alt="Classfy" 
          />
        </Link>
      </div>
      <div>
        <IconButton
          color="inherit"
          onClick={onMenuOpen}
        >
          <i className='material-icons'>menu</i>
        </IconButton>
        <IconButton 
          color="inherit"
          onClick={onLogout}
        >
          <i className='material-icons'>logout</i>
        </IconButton>
      </div>
    </header>
  )
}

export default Header