import { Card, CardContent } from "@mui/material"
import { GoogleLogin } from "@react-oauth/google"
import css from "./GoogleLinkCard.module.css"

const GoogleLinkCard = ({ src, alt, description, link }) => {

  const handleSuccess = (data) => {
    const token = data.credential
    window.open(`${link}?access_token=${token}`, "_blank")
  }

  return (
    <div className={css.main}>
      <Card className={css.card} raised>
        <CardContent className={css.content}>
          <img className={css.cover} src={src} alt={alt} />
          <p className={css.description}>
            {description}
          </p>
          <GoogleLogin
            onSuccess={handleSuccess}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default GoogleLinkCard