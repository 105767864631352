import { Link } from "react-router-dom"
import css from "./Error.module.css"

const Error = () => (
  <div className={css.main}>
    <p className={css.text}>
      No sé como has llegado hasta aquí, pero aquí no hay nada.
    </p>
    <p className={css.text}>
      <Link className={css.link} to="/">Empecemos de nuevo</Link>
    </p>
  </div>
)

export default Error
