import { useState } from "react"
import { Outlet, redirect } from "react-router-dom"
import { getAccessToken } from "../../utils/auth"
import Header from "../Header/Header"
import SideMenu from "../SideMenu/SideMenu"
import css from "./Layout.module.css"

const Layout = () => {

  const [openHeader, setOpenHeader] = useState(false)

  return (
    <div className={css.main}>
      <Header 
        onMenuOpen={()=> setOpenHeader(s=> !s)} 
      />
      <SideMenu open={openHeader} />
      <div className={css.content}>
        <Outlet />
      </div>
    </div>
  )
}

export default Layout

export const loader = () => {
  const token = getAccessToken()
  if (!token) return redirect('/login')
  return true
}