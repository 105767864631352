export const setAccessToken = token=> localStorage.setItem('access_token', token)

export const getAccessToken = ()=> {
  try {
    return JSON.parse(localStorage.getItem('access_token'))
  } catch (e) {
    return null
  }
}

export const removeAccessToken = ()=> localStorage.removeItem('access_token')