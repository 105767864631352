import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Login, {loader as loginLoader } from './views/Login/Login'
import Layout, { loader as layoutLoader } from './layout/Layout/Layout'
import Error from './views/Error/Error'
import Classfy from './views/Classfy/Classfy'
import Home from './views/Home/Home'
import Mundoestudiante from './views/Mundoestudiante/Mundoestudiante'

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
    loader: loginLoader
  },
  {
    path: '/',
    element: <Layout />,
    errorElement: <Error />,
    loader: layoutLoader,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: '/classfy',
        element: <Classfy />,
      },
      {
        path: '/mundoestudiante',
        element: <Mundoestudiante />,
      },
    ]
  }
])

const App = ()=> <RouterProvider router={router} />

export default App