import { Button, Card, CardContent } from "@mui/material"
import css from "./LinkCard.module.css"

const LinkCard = ({ badge, badgeType, src, alt, description, link }) => (
  <div className={css.main}>
    <Card className={css.card} raised>
      <CardContent className={css.content}>
        {badge && (
          <div className={css.badge} data-type={badgeType}>
            {badge}
          </div>
        )}
        <img className={css.cover} src={src} alt={alt} />
        <p className={css.description}>
          {description}
        </p>
        <Button
          variant="contained"
          color="primary"
          onClick={() => window.open(link, "_blank")}
        >
          Acceder
        </Button>
      </CardContent>
    </Card>
  </div>
)

export default LinkCard