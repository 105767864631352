import { Card, CardContent } from "@mui/material"
import { GoogleLogin } from "@react-oauth/google"
import { useState } from "react"
import { redirect, useNavigate } from "react-router-dom"
import { getAccessToken, setAccessToken } from "../../utils/auth"
import css from "./Login.module.css"

const ErrorMessage = ({ error }) => {
  if (!error) return null
  return <div className={css.error}>{error}</div>
}

const Login = ()=> {

  const navigate = useNavigate()

  const [error, setError] = useState('')

  const handleError = () =>
    setError("Ha habido un error iniciando sesión. Inténtelo de nuevo")

  const handleSuccess = (data) => {
    const token = data.credential
    fetch(`https://api.mundoestudiante.cloud/login/empleados?token=${token}`)
      .then((res) => res.json())
      .then(({ resultado, ...data }) => setAccessToken(JSON.stringify(data)))
      .then(() => navigate('/'))
      .catch(handleError)
  }

  return (
    <div className={css.main}>
      <ErrorMessage error={error} />
      <Card raised>
        <CardContent className={css.card}>
          <h1 className={css.title}>Portal de empleados</h1>
          <div className={css.logos}>
            <img
              className={css.logo}
              data-logo='mundoestudiante'
              src='/mundoestudiante.png'
              alt="mundoestudiante" 
            />
            <span className={css.separator} />
            <img 
              className={css.logo} 
              data-logo='classfy'
              src='/classfy.png' 
              alt="mundoestudiante" 
            />
          </div>
          <GoogleLogin
            size='large'
            logo_alignment="center"
            useOneTap
            onSuccess={handleSuccess}
            onError={handleError}
          />
        </CardContent>
      </Card>
    </div>
  )
}

export default Login

export const loader = () => {
  const token = getAccessToken()
  if (token) return redirect('/')
  return true
}