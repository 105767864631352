import React from "react"
import { Link } from "react-router-dom"
import { Avatar, Drawer, List, ListItem, ListItemText } from "@mui/material"
import { getAccessToken } from "../../utils/auth"
import css from "./SideMenu.module.css"

const ListItemLink = ({ text, to }) => (
  <ListItem className={css.link} component={Link} to={to}>
    <ListItemText primary={text} />
  </ListItem>
)

const SideMenu = ({ open }) => {

  const user = getAccessToken()

  return (
    <Drawer
      className={css.main}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{ paper: css.paper }}
    >
      <div className={css.header}>
        <div className={css.profile}>
          <Avatar alt={user.username} src={user.foto} />
          <p className={css.name}>{user.username}</p>
        </div>
      </div>
      <List>
        <ListItemLink to="/mundoestudiante" text="mundoestudiante" />  
        <ListItemLink to="/classfy" text="Classfy" />
      </List>
    </Drawer>
  )
}

export default SideMenu