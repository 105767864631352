import LinkCard from "../../components/LinkCard/LinkCard"
import css from "./Classfy.module.css"

const Classfy = () => (
  <div className={css.main}>
    <LinkCard
      src='/classfy-profes.png'
      alt="Máximo Barbeito"
      description="Plataforma online para profesores"
      link="https://teacher.live.mundoestudiante.com/"
    />
    <LinkCard
      src='/classfy-alumnos.png'
      alt="Victoria Barbeito"
      description="Plataforma online para alumnos"
      link="https://student.live.mundoestudiante.com/"
    />
    <LinkCard
      src='/mundoestudiante.png'
      alt="Administración"
      description="Plataforma de administración del online"
      link="https://admin.live.mundoestudiante.com/"
    />
  </div>
)

  export default Classfy