import GoogleLinkCard from "../../components/GoogleLinkCard/GoogleLinkCard"
import LinkCard from "../../components/LinkCard/LinkCard"
import css from "./Mundoestudiante.module.css"

const Mundoestudiante = () => (
  <div className={css.main}>
    <GoogleLinkCard
      src='/mundoestudiante-agora.png'
      alt="Ágora"
      description="Gestión de alumnos"
      link="https://agora.mundoestudiante.com"
    />
    <GoogleLinkCard
      src='/mundoestudiante-core.png'
      alt="Core"
      description="Panel de administración"
      link="https://core.mundoestudiante.com"
    />
    <LinkCard
      src='/mundoestudiante-aula-virtual.png'
      alt="Aula virtual"
      description="Moodle con contenidos para alumnos"
      link="https://aulavirtual.mundoestudiante.com/"
    />
    <LinkCard
      src='/mundoestudiante-brain.png'
      alt="Brain"
      description="Brain - recursos para profesores"
      link="https://brain.mundoestudiante.cloud/"
    />
    <LinkCard
      src='/mundoestudiante-firmas.png'
      alt="Firmas"
      description="Firma corporativa para emails"
      link="https://firma.mundoestudiante.cloud/"
    />
    <LinkCard
      src='/mundoestudiante-core.png'
      alt="Core"
      badge='beta v2'
      badgeType='warning'
      description="Panel de administración"
      link="https://core.mundoestudiante.cloud/"
    />
  </div>
)

export default Mundoestudiante